.addAmountMenu {
  position: absolute;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #e2e9f0;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 15px;
  padding-left: 20px;
  z-index: 10;
  height: 112px;
  width: 140px;
}

.addAmountHeader {
  margin-bottom: 10px;
}

.addAmountText {
  width: 128px;
  border-radius: 20px;
  border: none;
  height: 24px;
  padding-left: 10px;
}

.applyButton {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  margin-bottom: 15px;
}
