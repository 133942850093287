.groupBy {
  position: absolute;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #e2e9f0;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 15px;
  z-index: 10;
}

.groupByHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.groupTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.groupByButtons {
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  margin-top: 20px;
}
