.groupFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #e5ebf2;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding: 5px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.groupFilterImage {
  height: 12px;
  width: 12px;
}
