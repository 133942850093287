.tableTabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 10px;
  gap: 10px;
}

.tabBubble,
.selectedTabBubble,
.disabledTabBubble {
  display: flex;
  padding: 7px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #033f75;
  transition: all 0.2s ease;
  background: #dae4eca8;
}

.selectedTabBubble {
  background: #19bd53;
  color: white;
}

.disabledTabBubble {
  background: #e2e8ed;
  color: #8a9cab;
}

.tabBubble:hover {
  background-color: #d6dfe6;
}

.selectedTabBubble:hover {
  background-color: #00a03a;
}
