.loading {
  height: calc(100vh - 40px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.loadingImg {
  animation: spin 2s linear infinite;
}
