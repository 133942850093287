.filterMenu {
  position: absolute;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #e2e9f0;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 15px;
  z-index: 10;
}

.filterHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterInputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
  margin: 10px 0 25px 0;
}

.filterButtons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}

.filterMenuIcon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.filterText {
  border-radius: 20px;
  border: none;
  height: 30px;
  padding-left: 10px;
}
