.ribbonPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: calc(100vw - 100px);
  z-index: 1000;
  margin-top: 10px;
}

.ribbonPopupBar {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 20px;
  height: 3px;
  background-color: #19bd53;
}

.ribbonPopupMessage {
  width: 100%;
  background-color: white; /* Add a background color if needed */
  padding: 10px 20px; /* Adjust padding for better appearance */
  border-radius: 4px; /* Optional: round the corners of the message box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for better visibility */
}
