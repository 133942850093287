.tableNameSelector {
  position: relative;
  display: inline-block;
}

.tableNameHeader {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableNameArrow {
  margin-left: 5px;
  height: 20px;
}

.tableNameOptions {
  position: absolute;
  padding: 20px;
  padding-right: 40px;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: #f0f4fa;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.tableNameOption {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tableNameOption:hover,
.tableNameOption.selected {
  background-color: #d1d6e3;
  padding: 10px;
  border-radius: 10px;
}
