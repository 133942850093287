.submitPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.submitPopupContent {
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
}

.submitPopupHeader {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.submitPopupBody {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 40px;
}

.submitPopupFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
