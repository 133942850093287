@import "@silevis/reactgrid/styles.css";

.mainPage {
  background: #f2f5f8;
  width: calc(100vw - 100px);
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}

.title {
  font-family: "Segoe UI";
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: left;
  color: #033f75;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 30px;
}

.contentContainer {
  background: #ffffff70;
  border-radius: 20px;
  padding: 20px;
  height: calc(100% - 160px);
  box-shadow: 0px 4px 4px 0px #6464643d;
  display: flex;
  flex-direction: column;
}

.tableBackground {
  background: #ffffff;
  height: calc(100% - 120px);
  border-radius: 20px;
  padding: 15px 20px;
}

.headerButtons {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.reactgrid-content .rg-pane .rg-cell {
  font-family: "Segoe UI";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px !important;
  border-left: 1px solid #e5e7ea !important;
  border-right: 1px solid #e5e7ea !important;
  border-bottom: 1px solid #e5e7ea !important;
  color: #033f75;
}

.rg-summary-cell {
  border-top: solid 1px #003e75 !important;
}

.reactgrid-content .rg-pane .rg-cell > div {
  text-align: right !important;
}

.reactgrid-content .rg-pane.rg-pane-left > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rg-header-cell {
  font-weight: 600 !important;
  background-color: inherit !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rg-header-cell::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-image: url("../public/Chevron.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 2px;
}

.groupByContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.resetCountdown {
  position: absolute;
  bottom: 0;
  right: 0;
}
