.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.logo {
  width: 106px;
  height: 30px;
}

.userName {
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #033f75;
  cursor: pointer;
}

.logoutContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.logoutArrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.logout {
  margin-top: 10px;
  margin-left: 20px;
  background-color: #e2e9f0;
  position: absolute;
  width: 141px;
  height: 44px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 4px 4px 0px #6868681a;
}

.hovered {
  background-color: #939597;
}
