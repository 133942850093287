.dropdown {
  position: relative;
  width: 185px;
}

.dropdownHeader {
  border-radius: 20px;
  cursor: pointer;
  height: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.dropdownText {
  margin-left: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-80%) rotate(45deg);
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.dropdownOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
}

.dropdownOption {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdownOption:hover {
  background-color: #d1d6e3;
}
