.tableContainer {
  background: #ffffff;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 100%;
  overflow: auto;
}

.reactgrid-content .rg-pane.rg-pane-left > div {
  justify-content: flex-start;
}
