.assignMenu {
  position: absolute;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #e2e9f0;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 15px;
  z-index: 100;
}

.assignPerson {
  display: flex;
  flex-direction: row;
  height: 30px;
  box-shadow: 0px 4px 4px 0px #6868681a;
  background: #ffffff;
  border-radius: 20px;
  color: #033f75;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
}

.assignInput {
  padding-left: 10px;
  height: 24px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #6868681a;
}

.assignButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.assignHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.personPosition {
  color: #606b74;
  font-weight: 400;
  font-size: 10px;
}

.assignDropdown {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 30px);
  margin-top: 5px;
}

.assignDropdownItem {
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.assignDropdownItem:hover {
  background: #e2e9f0;
}

.initialCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #033f75;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 10px;
}

.assignCheckboxContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.assignCheckboxContainer label {
  margin: 5px;
  font-size: 14px;
}

.assignCheckbox {
  display: flex;
  align-items: center;
}

.box {
  width: 16px;
  height: 16px;
}
