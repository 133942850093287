.saveBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.savePopup {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  width: 500px;
  height: 200px;
}

.saveButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.saveHeader {
  margin: 20px 5px;
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.saveText {
  margin-left: 5px;
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
